export const environment = {
  COMPANY_NAME: "evollo",
  APP_NAME: "ID",
  production: true,
  apiUrl: "https://bradesco-account.api.evollo.cloud",
  qualityApiUrl: "https://bradesco-quality.api.evollo.cloud",
  recaptchaId: "6Le_4tQmAAAAALwMgkZF8dyNpcwY9KG1-a8_se6m",
  apps: {
    id: "https://bradesco.id.evollo.com.br/",
    admin: "https://bradesco.admin.evollo.com.br",
    quality: "https://bradesco.quality.evollo.com.br",
  },
  features: {
		enableSignUp: false,
		enableAzureSignUp: false,
		recaptchaEnabled: true,
		enableSupport: false,
		enableFaq: false
	}
};
